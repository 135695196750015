import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Spinner,
  Badge,
  ButtonGroup,
  ToggleButton,
  Form,
  Table,
} from "react-bootstrap";
import {
  useReactTable,
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import ErrorMessage from "../ErrorMessage";

interface Props {
  auth: any;
}

type OptionClass = {
  code: string;
  companyName: string;
  priceScanPercentage: number;
  priceScan: number;
  volScanPercentage: number;
  intraCommodityCharge: number;
  shortOptionMinimum: number;
};

const VolMonthlyAverage = ({ auth }: Props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [columns, setColumns] = useState<ColumnDef<OptionClass>[]>([]);
  const [data, setData] = useState([]);
  const [radioValue, setRadioValue] = useState("C");
  const [sorting, setSorting] = useState<SortingState>([]);

  const radios = [
    { name: "Call", value: "C" },
    { name: "Put", value: "P" },
  ];

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    let range = { lowest: 100, highest: 0 };

    function perc2color(perc: number) {
      var r,
        g,
        b = 0;
      if (perc < 50) {
        r = 255;
        g = Math.round(5.1 * perc);
      } else {
        g = 255;
        r = Math.round(510 - 5.1 * perc);
      }
      var h = r * 0x10000 + g * 0x100 + b * 0x1;
      return "#" + ("000000" + h.toString(16)).slice(-6) + "70";
    }

    const toPercent = (val: any) => {
      if (val === null || val === 0) {
        return null;
      }
      // Normalise the percent
      var max = range.highest - range.lowest;
      var scaledPer = ((val - range.lowest) / max) * 100;

      // Reverse the colours
      var color = perc2color(100 - scaledPer);
      return (
        <div style={{ backgroundColor: color }}>{`${(
          val * 100
        ).toLocaleString()}`}</div>
      );
    };

    async function getParameters() {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_POSITIONS_API_URI}/api/v1/vol?optionType=${radioValue}`,
          {
            headers: { Authorization: `Bearer ${auth.getAccessToken()}` },
          }
        );
        if (!response.ok) {
          throw new Error("Network error.");
        }
        const data = await response.json();

        var seen_periods: any = {};
        var output: any = {};

        for (let i = 0; i < data.length; i++) {
          const row = data[i];

          var d = new Date(row.expiry);
          var df = `_${d.getDate()}_${d.getMonth()}_${d.getFullYear()}`;

          if (!seen_periods[df]) {
            seen_periods[df] = row.expiry;
          }
          if (!output[row.code]) {
            output[row.code] = {};
          }

          if (row.vol < range.lowest) {
            range.lowest = row.vol;
          }
          if (row.vol > range.highest) {
            range.highest = row.vol;
          }

          output[row.code][df] = row.vol;
        }

        var periods_in_order = Object.values(seen_periods);
        periods_in_order.sort();

        var allCodes = Object.keys(output);
        allCodes.sort();

        var c: ColumnDef<OptionClass>[] = [
          {
            header: "Code",
            accessorKey: "code",
          },
        ];

        periods_in_order.forEach(function (period: any, index) {
          var d = new Date(period);
          var df = `_${d.getDate()}_${d.getMonth()}_${d.getFullYear()}`;

          c.push({
            accessorKey: df,
            header: period,
            cell: (x) => toPercent(x.getValue() as number),
          });
        });

        setColumns(c);

        var rows: any = [];

        allCodes.forEach(function (code) {
          var new_row: any = {};
          new_row.code = code;

          Object.keys(seen_periods).forEach(function (period) {
            new_row[period] = output[code][period] || null;
          });
          rows.push(new_row);
        });

        setData(rows);
      } catch (exception) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getParameters();
  }, [auth, radioValue]);

  function headerDate(header: any) {
    const name = header.column.columnDef.header;
    var date = new Date(name);

    const sortArrow =
      {
        asc: "vol-date-asc",
        desc: "vol-date-desc",
      }[header.column.getIsSorted() as string] ?? null;

      console.log(sortArrow);

    return (
      <div>
        <span>
          {date.getDate()} {monthNames[date.getMonth()]}
          <span className="order-4"></span>
        </span>
        <br />
        <span>
          <h6 className={"mb-0 " +  sortArrow}>
            <Badge bg="secondary" className="bg-opacity-75">
              {date.getFullYear()}
            </Badge>
          </h6>
        </span>
      </div>
    );
  }

  function headerCode(header: any) {
    const name = header.column.columnDef.header;

    const sortArrow =
      {
        asc: " 🔼",
        desc: " 🔽",
      }[header.column.getIsSorted() as string] ?? null;

    return (
      <>
        {name}
        {sortArrow}
      </>
    );
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (loading) {
    return (
      <Col className="spinner">
        <Spinner
          animation="border"
          role="status"
          variant="secondary"
          className="spinner-center"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    );
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Container className="underlying-main" fluid>
      <Row className="mt-2 mb-2">
        <Col>
          <h6 className="text-center">Volatility - At The Money</h6>
          <Form>
            <Form.Label className="mb-2 me-2" htmlFor="inlineFormInputName1">
              Option Type
            </Form.Label>
            <ButtonGroup size="sm">
              {radios.map((radio, idx) => (
                <ToggleButton
                  type="radio"
                  key={idx}
                  id={`radio-${idx}`}
                  variant="light"
                  value={radio.value}
                  checked={radioValue === radio.value}
                  onChange={(e) => setRadioValue(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped hover size="sm" className="table-fixed">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "cursor-pointer select-none"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header === "Code"
                                ? headerCode(header)
                                : headerDate(header),
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default VolMonthlyAverage;
