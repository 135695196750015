import { Tooltip } from "react-tooltip";
import { currency } from "../../common";
import { TotalCell } from "./TotalCell";

function UnderlyingTotalsTable({ totals, isDark }) {
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table table-sm table-hover" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th className="select-none">
              <span id="tt-b-code">Code</span>
              <Tooltip anchorId="tt-b-code">
                <span>ASX code</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <div id="tt-b-delta-original">
                <span>Delta</span>
                <br />
                <small>Original</small>
              </div>
              <Tooltip anchorId="tt-b-delta-original">
                <span>The net delta for the underlying</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <div id="tt-b-delta-remaining">
                <span>Delta</span>
                <br />
                <small>Remaining</small>
              </div>
              <Tooltip anchorId="tt-b-delta-remaining">
                <span>
                  The residual delta after applying all inter-commodity
                  <br />
                  offsets
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-b-premium">Premium</span>
              <Tooltip anchorId="tt-b-premium">
                <span>Total premium margin</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-b-span-req">Span Req</span>
              <Tooltip anchorId="tt-b-span-req">
                <span>
                  SPAN Requirement
                  <br />
                  Also referred to as initial margin. The SPAN requirement is
                  <br />a component of total margin
                </span>
                <pre className="tt-formula mt-2 mb-0">
                  Max(Scan Risk + Intracommodity Spread Charge + Delivery Risk –
                  Intercommodity Spread Credit, Short Option Minimum)
                </pre>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-b-scan-risk">Scan Risk</span>
              <Tooltip anchorId="tt-b-scan-risk">
                <span>
                  The scan risk is derived from the risk arrays and is the
                  <br />
                  worst case scenario for a combined commodity. This
                  <br />
                  associated scenario is called the active scenario.
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <div id="tt-b-intra">
                <span>Intra</span>
                <br />
                <small>Charge</small>
              </div>
              <Tooltip anchorId="tt-b-intra">
                <span>
                  Intracommodity Charge
                  <br />A charge may be applied to positions across different
                  <br />
                  calendar months within the same class
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <div id="tt-b-inter">
                <span>Inter</span>
                <br />
                <small>Credit</small>
              </div>
              <Tooltip anchorId="tt-b-inter">
                <span>
                  Intercommodity credit
                  <br />
                  SPAN evaluates whether a credit is applicable for positions
                  <br />
                  in related instruments. The calculation of the delta based
                  <br />
                  intercommodity spread credit considers the weighted futures
                  <br />
                  price risk (WFPR), delta per spread ratio (DPSR), number of
                  <br />
                  spreads formed and the concession rate.
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-b-time-risk">Time Risk</span>
              <Tooltip anchorId="tt-b-time-risk">
                <span>
                  Time Risk
                  <br />
                  The time risk is estimated from the risk arrays by using the
                  <br />
                  combination of scenarios where there are no price movements
                  <br />
                  and opposite volatility changes (Scenario 1 and Scenario 2).
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-b-vol-risk">Vol Risk</span>
              <Tooltip anchorId="tt-b-vol-risk">
                <span>
                  Volatility Risk
                  <br />
                  The volatility risk is estimated from the risk arrays by
                  <br />
                  using the combination of scenarios where price movement is
                  <br />
                  the same but the opposite definition of volatility movement.
                </span>
                <pre className="tt-formula mt-2 mb-0">
                  Volatility Risk = (Active Scenario - Paired Scenario) / 2
                </pre>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-b-price-risk">Price Risk</span>
              <Tooltip anchorId="tt-b-price-risk">
                <span>
                  The price risk is estimated using estimates of scan risk,
                  <br />
                  volatility and time risk.
                </span>
                <pre className="tt-formula mt-2 mb-0">
                  Price Risk = Scan Risk - Volatility Risk - Time Risk
                </pre>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-b-wfrp">WFRP</span>
              <Tooltip anchorId="tt-b-wfrp">
                <span>
                  Weighted Futures Price Risk
                  <br />
                  The price risk and net delta can then be used to determine
                  <br />
                  the WFPR for the combined commodity as follows:
                </span>
                <pre className="tt-formula mt-2 mb-0">
                  Weighted Futures Price Risk = Price Risk / |Net Delta|
                </pre>
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          {totals.map((x) => (
            <tr key={x.underlyingCode}>
              <td>{x.underlyingCode}</td>
              <td>{x.netDelta.toLocaleString()}</td>
              <td>{x.netDeltaRemaining.toLocaleString()}</td>
              <td className="option-row-total">
                {x.premiumTotal.toLocaleString()}
              </td>
              <td className="option-row-total">
                {Math.trunc(
                  -x.scanRisk - x.intraCommodityOffset + x.interCommodityOffset
                ).toLocaleString()}
              </td>
              <td>{(-x.scanRisk).toLocaleString()}</td>
              <td>{x.intraCommodityOffset.toLocaleString()}</td>
              <td>{x.interCommodityOffset.toLocaleString()}</td>
              <td>{x.timeRisk.toLocaleString()}</td>
              <td>{x.volatilityRisk.toLocaleString()}</td>
              <td>{x.priceRisk.toLocaleString()}</td>
              <td>{x.wfpr.toLocaleString()}</td>
            </tr>
          ))}
          <tr>
            <td className="border-bottom-0" />
            <td className="border-bottom-0" />
            <td>
              <strong>Total</strong>
            </td>
            <td>
              <TotalCell
                value={totals.reduce((x, y) => x + y.premiumTotal, 0)}
                isDark={isDark}
              />
            </td>
            <td>
              <TotalCell
                value={totals.reduce(
                  (x, y) =>
                    x +
                    Math.round(
                      -y.scanRisk +
                        y.intraCommodityOffset +
                        y.interCommodityOffset
                    ),
                  0
                )}
                isDark={isDark}
              />
            </td>
            <td>
              <TotalCell
                value={totals.reduce((x, y) => x + -y.scanRisk, 0)}
                isDark={isDark}
              />
            </td>
            <td>
              <TotalCell
                value={totals.reduce((x, y) => x + y.intraCommodityOffset, 0)}
                isDark={isDark}
              />
            </td>
            <td>
              <TotalCell
                value={totals.reduce((x, y) => x + y.interCommodityOffset, 0)}
                isDark={isDark}
              />
            </td>
            <td>{currency(totals.reduce((x, y) => x + y.timeRisk, 0))}</td>
            <td>
              {currency(totals.reduce((x, y) => x + y.volatilityRisk, 0))}
            </td>
            <td>{currency(totals.reduce((x, y) => x + y.priceRisk, 0))}</td>
            <td>{currency(totals.reduce((x, y) => x + y.wfpr, 0))}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default UnderlyingTotalsTable;
