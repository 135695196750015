import { toast } from "react-toastify";

const settings = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export function warn(message) {
  toast.warn(message, settings);
}

export function info(message) {
  toast.info(message, settings);
}
