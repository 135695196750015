import React, { Fragment, useState } from "react";
import { Modal, Button, Form, Row, Alert } from "react-bootstrap";
import { shortDateHyphen } from "../../common";

const EditModal = (props) => {
  const { groups, show, handleSave, handleClose } = props;
  const initialPositions = groups
    .map((x) => x.positions)
    .flat()
    .map((x) => {
      return {
        securityCode: x.optionCode,
        expiryDate: x.expiryDate,
        exercisePrice: x.exercisePrice,
        optionType: x.optionType,
        units: x.units,
      };
    });

  const [positions, updatePositions] = useState([...initialPositions]);

  const updateUnits = (code, units) => {
    const newPositions = [...positions];
    const updatedPosition = newPositions.find((x) => x.securityCode === code);
    updatedPosition.units = units;
    updatePositions(newPositions);
  };

  const removePosition = (code) => {
    const filteredPositions = positions.filter((x) => x.securityCode !== code);
    updatePositions(filteredPositions);
  };

  const saveChanges = () => {
    // TODO: Async button saving
    handleSave(positions);
    handleClose();
  };

  const securityInfo = (position) => {
    var type = position.optionType.toUpperCase === "C" ? "Call" : "Put";
    var expiryDate = shortDateHyphen(new Date(position.expiryDate));

    return (
      <>
        <span className="col-2">{position.securityCode}</span>
        <span className="col text-muted">{`${position.exercisePrice} ${type} ${expiryDate}`}</span>
      </>
    );
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Positions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          {positions.length === 0 && (
            <Alert variant="warning">⚠️ Removing all positions</Alert>
          )}
          {positions.map((x) => (
            <Fragment key={x.securityCode}>
              <Row className="mb-1">
                {/* <Form.Label className="col">{securityInfo(x)}</Form.Label> */}
                {securityInfo(x)}
                <div className="col-5 d-flex">
                  <Form.Control
                    className="me-1"
                    value={x.units}
                    onChange={(e) =>
                      updateUnits(x.securityCode, e.target.value)
                    }
                    size="sm"
                    type="number"
                  />
                  <Button
                    className="ms-1 ms-auto"
                    variant="secondary"
                    size="sm"
                    onClick={() => removePosition(x.securityCode)}
                  >
                    Remove
                  </Button>
                </div>
              </Row>
            </Fragment>
          ))}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="btn-sm"
          onClick={() => updatePositions([])}
        >
          Remove all
        </Button>
        <Button variant="secondary" className="btn-sm" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" className="btn-sm" onClick={saveChanges}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditModal;
