import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { MoonLoader } from "react-spinners";
import * as toaster from "../Services/toaster";
import OptionsTable from "./OptionsTable";
import ThemeContext from "../Contexts/ThemeContext";
import UnderlyingTotalsTable from "./UnderlyingTotalsTable";
import MarginTotals from "./MarginTotals";
import SettingsModal from "./SettingsModal";
import Disclaimer from "./Footer/Disclaimer.js";
import EditModal from "./EditModal";
import ErrorMessage from "./ErrorMessage";
import { SyncIcon } from "@primer/octicons-react";
import { getMargins } from "../Services/apiService";
import * as localPortfolio from "./Portfolio/localPortfolio";
import "react-toastify/dist/ReactToastify.min.css";

function Portfolio({ auth }) {
  const { theme } = useContext(ThemeContext);

  const [settings, setSettings] = useState({});
  const [portfolioResponse, setPortfolioResponse] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [optionCode, setOptionCode] = useState("");
  const [units, setUnits] = useState("");
  const [showRiskArrays, setShowRiskArrays] = useState(true);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const portfolioIndex = portfolioResponse.portfolioIndex;
  const margins = portfolioResponse.marginResult ?? {};
  const groups = portfolioResponse.marginResult?.classGroups ?? [];

  // Theming
  const isDark = theme === "dark";
  const buttonVariant = theme === "light" ? "btn-light" : "btn-secondary";

  useEffect(() => {
    async function getUserPortfolio() {
      const response = await fetch(
        `${process.env.REACT_APP_POSITIONS_API_URI}/api/v1/load`,
        {
          headers: { Authorization: `Bearer ${auth.getAccessToken()}` },
        }
      );

      if (!response.ok) {
        throw new Error("Network error.");
      }

      const data = await response.json();

      setPortfolioResponse(data.portfolioResponse);
      setSettings({
        premiumMultiplier: data.portfolioResponse.premiumMultiplier,
        riskMultiplier: data.portfolioResponse.riskMultiplier,
      });
    }

    async function getLocalPortfolio() {
      const positions = localPortfolio.getPositions();
      if (positions) {
        const response = await getMargins(positions);
        setPortfolioResponse({
          marginResult: response.marginResult,
        });
      }
    }

    async function getPortfolios() {
      try {
        setLoading(true);
        if (auth.isAuthenticated()) {
          await getUserPortfolio();
        } else {
          setSettings(localPortfolio.initSettings());
          await getLocalPortfolio();
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }

    getPortfolios();
  }, [auth]);

  function addPosition() {
    const currentPositions = groups.map((group) => group.positions).flat();
    const currentCodes =
      currentPositions.map((position) => {
        return { securityCode: position.optionCode, units: position.units };
      }) || [];

    currentCodes.push({
      securityCode: optionCode,
      units: units,
    });

    updatePositions(currentCodes);
  }

  function refresh() {
    const currentPositions = groups.map((group) => group.positions).flat();

    const currentCodes =
      currentPositions.map((position) => {
        return { securityCode: position.optionCode, units: position.units };
      }) || [];

    updatePositions(currentCodes);
  }

  async function updatePositions(positions) {
    setIsUpdating(true);
    localPortfolio.savePositions(positions);
    try {
      const response = await getMargins(positions, auth);

      setPortfolioResponse((prev) => ({
        ...prev,
        marginResult: response.marginResult,
      }));
      setOptionCode("");
      setUnits("");
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
      setIsUpdating(false);
    }
  }

  function validateOption() {
    if (codeIsValid() && unitsIsValid()) {
      addPosition();
    } else {
      toaster.warn("Invalid code or units");
    }
  }

  function codeIsValid() {
    if (!optionCode) {
      return false;
    }

    if (optionCode.length !== 5 && optionCode.length !== 6) {
      return false;
    }
    //TODO: Response needs to return flat positions for add/delete
    // if (this.state.positions && this.state.positions.find(x => x.optionCode === this.state.optionCode)) {
    //   this.setState({
    //     codeIsInvalid: true,
    //     codeIsDirty: true,
    //     validationMessage: `${this.state.optionCode} has already been added`
    //   });
    //   return false;
    // }

    return true;
  }

  function unitsIsValid() {
    if (isNaN(Number(units))) {
      return false;
    }

    // We only support units from -1000 to +1000
    if (units < -1000 || units > 1000) {
      return false;
    }

    return true;
  }

  function getHeaders() {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (auth.isAuthenticated()) {
      headers.Authorization = `Bearer ${auth.getAccessToken()}`;
    }

    return headers;
  }

  function saveSettings(settings) {
    setIsUpdating(true);
    if (auth.isAuthenticated()) {
      fetch(`${process.env.REACT_APP_POSITIONS_API_URI}/api/v1/settings`, {
        headers: getHeaders(),
        method: "POST",
        body: JSON.stringify({
          portfolioIndex: portfolioIndex,
          premiumMultiplier: settings.premiumMultiplier,
          riskMultiplier: settings.riskMultiplier,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setSettings(settings);
            return;
          }
          throw new Error("Network error.");
        })
        .finally(() => {
          setIsUpdating(false);
        });
    } else {
      localPortfolio.saveSettings(settings);
      setSettings(settings);
      toaster.info("Setttings saved");
      setIsUpdating(false);
    }
  }

  // TODO: refactor
  function handleChange(e) {
    setOptionCode(e.target.value.toUpperCase().trim());
  }

  function handleUnits(e) {
    // TODO: Remove illegal characters like . etc.
    setUnits(e.target.value.trim());
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      validateOption();
    }
  }

  function handleCheckbox(e) {
    setShowRiskArrays(e.target.checked);
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <>
      <main className="portfolio-main">
        <div className="container-fluid mt-2">
          <Row>
            <Col>
              <div>
                <MarginTotals
                  margins={margins}
                  settings={settings}
                  showSettings={() => setShowSettingsModal(true)}
                  theme={theme}
                />
              </div>
              <SettingsModal
                settings={settings}
                show={showSettingsModal}
                handleClose={() => setShowSettingsModal(false)}
                saveSettings={saveSettings}
              />
            </Col>
          </Row>
          <div className="row row-cols-sm-auto g-3 align-items-center">
            <div className="col-12">
              <label className="visually-hidden" htmlFor="inlineFormInputCode">
                Code
              </label>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputCode"
                placeholder="Code"
                value={optionCode}
                onChange={(e) => handleChange(e)}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="col-12">
              <label className="visually-hidden" htmlFor="inlineFormInputUnits">
                Units
              </label>
              <input
                type="text"
                className="form-control"
                id="inlineFormInputUnits"
                placeholder="Units"
                pattern="^(?!-0)-?\d{1,}$"
                value={units}
                onChange={(e) => handleUnits(e)}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                autoCorrect="off"
              />
            </div>
            <div className="col-12 d-flex">
              <button
                type="submit"
                className={"btn me-1 " + buttonVariant}
                disabled={isUpdating}
                onClick={() => validateOption()}
              >
                Add
              </button>
              <button
                type="submit"
                className={"btn me-1 " + buttonVariant}
                disabled={isUpdating || groups.length === 0}
                onClick={() => setShowEditModal(true)}
              >
                Edit
              </button>
              <button
                type="submit"
                className={"btn me-2 " + buttonVariant}
                disabled={isUpdating || groups.length === 0}
                onClick={() => refresh()}
              >
                <SyncIcon />
              </button>
              <div className="mt-1">
                {(isUpdating || loading) && (
                  <MoonLoader
                    size={22}
                    color={theme === "dark" ? "#adb5bd" : undefined}
                  />
                )}
              </div>
            </div>
            <div className="col-12 ms-auto">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={showRiskArrays}
                  onChange={(e) => handleCheckbox(e)}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Show Risk Arrays
                </label>
              </div>
            </div>
          </div>
          <div>
            {groups.length === 0 && !loading && (
              <div className="alert alert-light text-center mt-3" role="alert">
                <h6 className="m-0">Add a position to get started.</h6>
              </div>
            )}
            {groups.length !== 0 && (
              <div style={{ overflowX: "auto" }}>
                <OptionsTable
                  groups={groups}
                  hideKeys={!showRiskArrays}
                  isDark={isDark}
                />
              </div>
            )}
          </div>
          {groups.length !== 0 && (
            <div>
              <div style={{ overflowX: "auto" }}>
                <UnderlyingTotalsTable totals={groups} isDark={isDark} />
              </div>
            </div>
          )}
        </div>
      </main>
      <Disclaimer />
      <EditModal
        show={showEditModal}
        groups={groups}
        handleSave={updatePositions}
        handleClose={() => setShowEditModal(false)}
        key={Date.now()}
      />
    </>
  );
}

export default Portfolio;
