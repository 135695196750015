export const groupBy = (key, array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const shortDate = (value) => {
  return value.toLocaleString("en-AU", {
    day: "numeric",
    month: "short",
    year: "2-digit",
  });
};

export const shortDateHyphen = (value) => {
  return shortDate(value).replaceAll(" ", "-");
};

export const getCellColour = (key, highestKey, hideKeys) => {
  const style = {};
  if (key === highestKey) {
    style.background = "#ffe4e0";
    return style;
  }

  if (hideKeys) {
    style.display = "none";
  }

  return style;
};

export const getCellColor = (key, highestKey, hideKeys, opacity = 50) => {
  if (key === highestKey) {
    return opacity === 25
      ? "bg-danger-subtle text-end"
      : `text-bg-danger text-end`;
  }
  if (hideKeys) {
    return "d-none";
  }
  return "text-end ";
};

// export const getCellColor = (key, highestKey, hideKeys, opacity = 50) => {
//   if (key === highestKey) {
//     return (
//       `text-bg-danger bg-opacity-${opacity}` +
//       (opacity < 50 ? " text-dark" : "")
//     );
//   }
//   if (hideKeys) {
//     return "d-none";
//   }
//   return "";
// };

export const currency = (value) => {
  if (!value && value !== 0) {
    return "-";
  }

  return value.toLocaleString("en-AU", { style: "currency", currency: "AUD" });
};

export const number = (value) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};
