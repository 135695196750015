import { OptionRow } from "./OptionRow";
import TotalRow from "./TotalRow";

function OptionsGroup({ options, hideKeys, isDark }) {
  const data = {
    netDelta: options.reduce((x, y) => x + y.netDelta, 0),
    premium: options.reduce((x, y) => x + y.premium, 0),
  };

  const r = {
    r1: options.reduce((x, y) => x + y.r1, 0),
    r2: options.reduce((x, y) => x + y.r2, 0),
    r3: options.reduce((x, y) => x + y.r3, 0),
    r4: options.reduce((x, y) => x + y.r4, 0),
    r5: options.reduce((x, y) => x + y.r5, 0),
    r6: options.reduce((x, y) => x + y.r6, 0),
    r7: options.reduce((x, y) => x + y.r7, 0),
    r8: options.reduce((x, y) => x + y.r8, 0),
    r9: options.reduce((x, y) => x + y.r9, 0),
    r10: options.reduce((x, y) => x + y.r10, 0),
    r11: options.reduce((x, y) => x + y.r11, 0),
    r12: options.reduce((x, y) => x + y.r12, 0),
    r13: options.reduce((x, y) => x + y.r13, 0),
    r14: options.reduce((x, y) => x + y.r14, 0),
    r15: options.reduce((x, y) => x + y.r15, 0),
    r16: options.reduce((x, y) => x + y.r16, 0),
  };

  const highestKey = Object.keys(r).reduce((a, b) => (r[a] > r[b] ? a : b));

  return options.length > 0 ? (
    <>
      {options.map((x) => (
        <OptionRow
          option={x}
          highestKey={highestKey}
          hideKeys={hideKeys}
          isDark={isDark}
          key={x.optionCode}
        />
      ))}
      <TotalRow
        options={{ ...data, ...r }}
        highestKey={highestKey}
        isDark={isDark}
        hideKeys={hideKeys}
      />
    </>
  ) : null;
}

export default OptionsGroup;
