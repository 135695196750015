import React, { useState, useEffect } from "react";
import { Container, Spinner, Table } from "react-bootstrap";
import {
  useReactTable,
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import ErrorMessage from "../ErrorMessage";

interface Props {
  auth: any;
}

type OptionClass = {
  code: string;
  companyName: string;
  priceScanPercentage: number;
  priceScan: number;
  volScanPercentage: number;
  intraCommodityCharge: number;
  shortOptionMinimum: number;
};

function MarginParameters({ auth }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    async function getParameters() {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_POSITIONS_API_URI}/api/v1/parameters`,
          {
            headers: { Authorization: `Bearer ${auth.getAccessToken()}` },
          }
        );
        if (!response.ok) {
          throw new Error("Network error.");
        }
        const data = await response.json();
        setData(data);
      } catch (exception) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getParameters();
  }, [auth]);

  function toPercent(val: number) {
    return val === 0 ? "-" : `${(val * 100).toLocaleString()}`;
  }

  function formatZero(val: number) {
    return val === 0 ? "-" : val;
  }

  const columns: ColumnDef<OptionClass>[] = [
    {
      header: "Code",
      accessorKey: "code",
    },
    {
      header: "Company Name",
      accessorKey: "companyName",
    },
    {
      header: "Price Scan %",
      accessorKey: "priceScanPercentage",
      cell: (x) => toPercent(x.getValue() as number),
    },
    {
      header: "Price Scan",
      accessorKey: "priceScan",
      cell: (x) => formatZero(x.getValue() as number),
    },
    {
      header: "Vol Scan %",
      accessorKey: "volScanPercentage",
      cell: (x) => toPercent(x.getValue() as number),
    },
    {
      header: "Intra Commodity Charge",
      accessorKey: "intraCommodityCharge",
      cell: (x) => formatZero(x.getValue() as number),
    },
    {
      header: "SOM",
      accessorKey: "shortOptionMinimum",
    },
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (loading) {
    return (
      <Spinner
        animation="border"
        role="status"
        variant="secondary"
        className="spinner-center"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Container fluid className="underlying-main pt-2">
      <Table striped hover size="sm">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default MarginParameters;
