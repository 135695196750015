import { Tooltip } from "react-tooltip";
import Badge from "react-bootstrap/Badge";
import { currency } from "../../common";
import { GearIcon } from "@primer/octicons-react";
import { textColor } from "../../Common/colors";

function MarginTotals({ margins, settings, showSettings, theme }) {
  const { premiumMultiplier, riskMultiplier } = settings;
  const { premium, spanRequirement } = margins;

  var span = spanRequirement === 0 ? 0 : -spanRequirement;

  const premiumCharge = premium * premiumMultiplier;
  const premiumAdditional =
    premiumMultiplier === 1 ? 0 : premium * (premiumMultiplier - 1);

  const riskCharge = span * riskMultiplier;
  const riskAdditional = riskMultiplier === 1 ? 0 : span * (riskMultiplier - 1);

  const totalCharge = premiumCharge + riskCharge;

  const premiumMultiplierDisplay = premiumMultiplier
    ? `${premiumMultiplier}x`
    : "-";

  const riskMultiplierDisplay = riskMultiplier ? `${riskMultiplier}x` : "-";

  const isDark = theme === "dark";

  return (
    <div>
      <div className="d-flex">
        <div className="card mb-3 total-card rounded-0 border-end-0 rounded-start bg-body-tertiary">
          <div className="card-body p-2">
            <h6 className="card-title">Total Margin</h6>
            <h6 className={`card-text ${textColor(totalCharge, isDark)}`}>
              {currency(totalCharge)}
            </h6>
            <div className="d-flex">
              <small className="me-auto fw-semibold">ASX Margin:</small>
              <small>{currency(premium - spanRequirement)}</small>
            </div>
            <small className="fw-semibold">
              {premiumMultiplier}x Premium + {riskMultiplier}x Risk
            </small>
          </div>
        </div>
        <div className="card mb-3 total-card rounded-0 border-end-0 bg-body-tertiary">
          <div className="card-body p-2">
            <h6 className="card-title d-flex">
              <span>Premium</span>
              <Badge
                bg="secondary"
                id="tt-premium-multiplier"
                onClick={showSettings}
                className="ms-1 margin-total-multiplier"
                data-tooltip-id="premium-margin-multiplier-tooltip"
                data-tooltip-content="The premium margin multiplier."
              >
                {premiumMultiplierDisplay}
              </Badge>
              <Tooltip id="premium-margin-multiplier-tooltip" />
              <div className="ms-auto pointer" onClick={showSettings}>
                <GearIcon />
              </div>
            </h6>
            <h6 className={`card-text ${textColor(premium, isDark)}`}>
              {currency(premiumCharge)}
            </h6>
            <div className="d-flex">
              <small className="me-auto fw-semibold">Premium:</small>
              <small>{currency(premium)}</small>
            </div>
            <div className="d-flex">
              <small className="me-auto fw-semibold">Additional:</small>
              <small>{currency(premiumAdditional)}</small>
            </div>
          </div>
        </div>
        <div className="card mb-3 total-card rounded-0 rounded-end bg-body-tertiary">
          <div className="card-body p-2">
            <h6 className="card-title d-flex">
              <span>Risk</span>
              <Badge
                bg="secondary"
                id="tt-risk-multiplier"
                onClick={showSettings}
                className="ms-1 margin-total-multiplier"
                data-tooltip-id="risk-margin-multiplier-tooltip"
                data-tooltip-content="The risk margin multiplier."
              >
                {riskMultiplierDisplay}
              </Badge>
              <Tooltip id="risk-margin-multiplier-tooltip" />
              <div className="ms-auto pointer" onClick={showSettings}>
                <GearIcon />
              </div>
            </h6>
            <h6 className={`card-text ${textColor(span, isDark)}`}>
              {currency(riskCharge)}
            </h6>
            <div className="d-flex">
              <small className="me-auto fw-semibold">SPAN:</small>
              <small>{currency(span)}</small>
            </div>
            <div className="d-flex">
              <small className="me-auto fw-semibold">Additional:</small>
              <small>{currency(riskAdditional)}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarginTotals;
