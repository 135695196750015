import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";

const SettingsModal = ({ show, handleClose, settings, saveSettings }) => {
  const [settingsLocal, setSettingsLocal] = useState({
    premiumMultiplier: "",
    riskMultiplier: "",
  });

  function handleSave() {
    //TODO check input is valid
    saveSettings(settingsLocal);
    handleClose();
  }

  useEffect(() => {
    if (show) {
      setSettingsLocal((prev) => ({
        ...prev,
        ...settings,
      }));
    }
  }, [show, settings]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Row>
            <Form.Label column="sm" lg={6}>
              Premium Margin Multiplier
            </Form.Label>
            <Col>
              <Form.Control
                value={settingsLocal.premiumMultiplier}
                onChange={(e) =>
                  setSettingsLocal({
                    ...settingsLocal,
                    premiumMultiplier: e.target.value,
                  })
                }
                size="sm"
                type="number"
                placeholder="e.g. 1.5 or 1 default is 1."
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Form.Label column="sm" lg={6}>
              Risk Margin (Span) Multiplier
            </Form.Label>
            <Col>
              <Form.Control
                value={settingsLocal.riskMultiplier}
                onChange={(e) =>
                  setSettingsLocal({
                    ...settingsLocal,
                    riskMultiplier: e.target.value,
                  })
                }
                size="sm"
                type="number"
                placeholder="e.g. 1.5 default is 1."
              />
            </Col>
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button size="sm"  variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SettingsModal;
