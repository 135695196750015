import React from "react";
import logo from "../../Common/logo.svg";

const Footer = () => {
  return (
    <div className="container">
      <footer className="pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 col-md">
            <img className="mb-2" src={logo} alt="" width="24" height="24" />
            <small className="d-block mb-3 text-muted">
              ETO Margins 2019-{new Date().getFullYear()}
            </small>
          </div>
          <div className="col-6 col-md">
            <h5>Margin Estimator</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="/portfolio">
                  Portfolio
                </a>
              </li>
              <li>
                <a className="text-muted" href="/parameters">
                  Underlying Parameters
                </a>
              </li>
              <li>
                <a className="text-muted" href="/intercommodityspreads">
                  Inter-commodity Spreads
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>Resources</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="/help">
                  Documentation
                </a>
              </li>
              <li>
                <a className="text-muted" href="/links">
                  Links
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <h5>ETO Margins</h5>
            <ul className="list-unstyled text-small">
              <li>
                <a className="text-muted" href="/about">
                  About
                </a>
              </li>
              <li>
                <a className="text-muted" href="/contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
