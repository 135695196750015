import React, { useContext } from "react";
import { LinkContainer } from "react-router-bootstrap";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Button,
} from "react-bootstrap";
import { SunIcon, MoonIcon } from "@primer/octicons-react";
import ThemeContext from "./Contexts/ThemeContext";

function Header({ auth }) {
  const { theme, changeTheme } = useContext(ThemeContext);
  const { login, logout, isAuthenticated } = auth;
  const loggedIn = auth.isAuthenticated();

  const isDark = theme === "dark";

  return (
    <Navbar expand="lg" data-bs-theme={theme}>
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            <span className="logo-home">ETO Margins</span>
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0">
            <LinkContainer to="/portfolio">
              <Nav.Link>Portfolio</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Volatility">
              <LinkContainer to="/volatility">
                <NavDropdown.Item>Monthly At The Money</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown title="Parameters">
              <LinkContainer to="/parameters">
                <NavDropdown.Item>Margin Parameters</NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to="/intercommodityspreads">
                <NavDropdown.Item>Inter-commodity Spreads</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/help">
              <Nav.Link>Help</Nav.Link>
            </LinkContainer>
          </Nav>

          <Form className="d-flex align-items-center">
            <div className="me-2 pointer" onClick={() => changeTheme()}>
              {isDark ? <MoonIcon size={24} /> : <SunIcon size={24} />}
            </div>
            <Button
              variant="outline-secondary"
              onClick={isAuthenticated() ? logout : login}
            >
              {loggedIn ? "Logout" : "Login"}
            </Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
