import { useState } from "react";

function useTheme() {
  const getStoredTheme = () => localStorage.getItem("theme");
  const setStoredTheme = (theme) => localStorage.setItem("theme", theme);

  const getPreferredTheme = () => {
    const storedTheme = getStoredTheme();
    if (storedTheme) {
      return storedTheme;
    }

    return window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
  };

  const setGlobalTheme = (theme) => {
    if (
      theme === "auto" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      document.documentElement.setAttribute("data-bs-theme", "dark");
    } else {
      document.documentElement.setAttribute("data-bs-theme", theme);
    }
  };

  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", () => {
      const storedTheme = getStoredTheme();
      if (storedTheme !== "light" && storedTheme !== "dark") {
        setGlobalTheme(getPreferredTheme());
      }
    });

  const preferredTheme = getPreferredTheme();
  setStoredTheme(preferredTheme);
  setGlobalTheme(preferredTheme);

  const [theme, setTheme] = useState(preferredTheme);

  function changeTheme() {
    const newTheme = theme === "light" ? "dark" : "light";
    setStoredTheme(newTheme);
    setGlobalTheme(newTheme);
    setTheme(newTheme);
  }

  const value = { theme, changeTheme };

  return value;
}

export default useTheme;
