import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const ErrorMessage = () => {
  const location = useLocation();

  const handleRefresh = () => {
    // This will trigger a dom reload as well.
    // TODO: Need to look in to how to handle route reloading in react-router
    window.location = location.pathname;
  };
  return (
    <>
      <Row className="error-message">
        <Col>
          <div className="mt-5 mb-5">
            <p>Something went wrong</p>
            <p>
              <a className="link" href="/portfolio" onClick={handleRefresh}>
                Click here to try again.
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ErrorMessage;
