import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import OptionsGroup from "./OptionsGroup";

function OptionsTable({ groups, hideKeys, isDark }) {
  const toggleTableHeader = () => {
    return { display: hideKeys ? "none" : "" };
  };

  return (
    <div className="mt-1">
      <table className="table table-sm table-hover">
        <thead className="text-center">
          <tr className="lh-sm text-nowrap">
            <th className="select-none">
              <span id="tt-a-code">Code</span>
              <Tooltip anchorId="tt-a-code">
                <span>ASX code</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-units">Units</span>
              <Tooltip anchorId="tt-a-units">
                <span>
                  Number of contracts
                  <br />
                  Negative for short, positive for long.
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-expiry">Expiry</span>
              <Tooltip anchorId="tt-a-expiry">
                <span>The option contract expiry day</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-strike">Strike</span>
              <Tooltip anchorId="tt-a-strike">
                <span>The option contract exercise price</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-cp">C/P</span>
              <Tooltip anchorId="tt-a-cp">
                <span>Call or Put</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-spc">SPC</span>
              <Tooltip anchorId="tt-a-spc">
                <span>Shares per contract</span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-vol">Vol</span>
              <Tooltip anchorId="tt-a-vol">
                <span>
                  Volatility
                  <br />
                  This value is obtained from the ASX SPAN parameter file
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-delta">Delta</span>
              <Tooltip anchorId="tt-a-delta">
                <span>
                  Delta
                  <br />
                  The option contact delta
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-netdelta">
                <span>Net</span>
                <br />
                <span>Delta</span>
              </span>
              <Tooltip anchorId="tt-a-netdelta">
                <span>
                  Net Delta
                  <br />
                  The option contact delta multiplied by the number of contacts
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              <span id="tt-a-prem">Prem</span>
              <Tooltip anchorId="tt-a-prem">
                <span>
                  The option contact premium
                  <br />
                  This is the fair value of the contact
                </span>
              </Tooltip>
            </th>
            <th className="select-none">
              {hideKeys ? (
                <>
                  <span>Scan</span>
                  <br />
                  <span>Risk</span>
                </>
              ) : (
                <>
                  <div id="tt-a-r1">
                    <div className="bg-body-tertiary">1</div>
                    <small> P: 0</small>
                    <br />
                    <small>V: +1</small>
                  </div>
                  <Tooltip anchorId="tt-a-r1">
                    <span>
                      SPAN scenario 1:
                      <br />
                      Underlying equity price unchanged
                      <br />
                      Volatility up
                      <br />
                      Time reduced by 2 days
                    </span>
                  </Tooltip>
                </>
              )}
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r2">
                <div className="bg-body-tertiary">2</div>
                <small>P: 0</small>
                <br />
                <small>V: -1</small>
              </div>
              <Tooltip anchorId="tt-a-r2">
                <span>
                  SPAN scenario 2:
                  <br />
                  Underlying equity price unchanged
                  <br />
                  Volatility down
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r3">
                <div className="bg-body-tertiary">3</div>
                <small>P: +1/3</small>
                <br />
                <small>V: +1</small>
              </div>
              <Tooltip anchorId="tt-a-r3">
                <span>
                  SPAN scenario 3:
                  <br />
                  Underlying equity price up 1/3 range
                  <br />
                  Volatility up
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r4">
                <div className="bg-body-tertiary">4</div>
                <small>P: +1/3</small>
                <br />
                <small>V: -1</small>
              </div>
              <Tooltip anchorId="tt-a-r4">
                <span>
                  SPAN scenario 4:
                  <br />
                  Underlying equity price up 1/3 range
                  <br />
                  Volatility down
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r5">
                <div className="bg-body-tertiary">5</div>
                <small>P: -1/3</small>
                <br />
                <small>V: +1</small>
              </div>
              <Tooltip anchorId="tt-a-r5">
                <span>
                  SPAN scenario 5:
                  <br />
                  Underlying equity price down 1/3 range
                  <br />
                  Volatility up
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r6">
                <div className="bg-body-tertiary">6</div>
                <small>P: -1/3</small>
                <br />
                <small>V: -1</small>
              </div>
              <Tooltip anchorId="tt-a-r6">
                <span>
                  SPAN scenario 6:
                  <br />
                  Underlying equity down up 1/3 range
                  <br />
                  Volatility down
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r7">
                <div className="bg-body-tertiary">7</div>
                <small>P: +2/3</small>
                <br />
                <small>V: +1</small>
              </div>
              <Tooltip anchorId="tt-a-r7">
                <span>
                  SPAN scenario 7:
                  <br />
                  Underlying equity down up 2/3 range
                  <br />
                  Volatility up
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r8">
                <div className="bg-body-tertiary">8</div>
                <small>P: +2/3</small>
                <br />
                <small>V: -1</small>
              </div>
              <Tooltip anchorId="tt-a-r8">
                <span>
                  SPAN scenario 8:
                  <br />
                  Underlying equity down up 2/3 range
                  <br />
                  Volatility down
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r9">
                <div className="bg-body-tertiary">9</div>
                <small>P: -2/3</small>
                <br />
                <small>V: +1</small>
              </div>
              <Tooltip anchorId="tt-a-r9">
                <span>
                  SPAN scenario 9:
                  <br />
                  Underlying equity down 2/3 range
                  <br />
                  Volatility up
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r10">
                <div className="bg-body-tertiary">10</div>
                <small>P: -2/3</small>
                <br />
                <small>V: -1</small>
              </div>
              <Tooltip anchorId="tt-a-r10">
                <span>
                  SPAN scenario 10:
                  <br />
                  Underlying equity down up 2/3 range
                  <br />
                  Volatility down
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r11">
                <div className="bg-body-tertiary">11</div>
                <small>P: +3/3</small>
                <br />
                <small>V: +1</small>
              </div>
              <Tooltip anchorId="tt-a-r11">
                <span>
                  SPAN scenario 11:
                  <br />
                  Underlying equity up 3/3 range
                  <br />
                  Volatility up
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r12">
                <div className="bg-body-tertiary">12</div>
                <small>P: +3/3</small>
                <br />
                <small>V: -1</small>
              </div>
              <Tooltip anchorId="tt-a-r12">
                <span>
                  SPAN scenario 12:
                  <br />
                  Underlying equity up 3/3 range
                  <br />
                  Volatility down
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r13">
                <div className="bg-body-tertiary">13</div>
                <small>P: -3/3</small>
                <br />
                <small>V: +1</small>
              </div>
              <Tooltip anchorId="tt-a-r13">
                <span>
                  SPAN scenario 13:
                  <br />
                  Underlying equity price down 3/3 range
                  <br />
                  Volatility up
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r14">
                <div className="bg-body-tertiary">14</div>
                <small>P: -3/3</small>
                <br />
                <small>V: -1</small>
              </div>
              <Tooltip anchorId="tt-a-r14">
                <span>
                  SPAN scenario 14:
                  <br />
                  Underlying equity price down 3/3 range
                  <br />
                  Volatility down
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r15">
                <div className="bg-body-tertiary">15</div>
                <small>P: 2</small>
                <br />
                <small>W: 0.35</small>
              </div>
              <Tooltip anchorId="tt-a-r15">
                <span>
                  SPAN scenario 15:
                  <br />
                  Underlying equity price up extreme move (cover 35% of loss)
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
            <th className="select-none" style={toggleTableHeader()}>
              <div id="tt-a-r16">
                <div className="bg-body-tertiary">16</div>
                <small>P: -2</small>
                <br />
                <small>W: 0.35</small>
              </div>
              <Tooltip anchorId="tt-a-r16">
                <span>
                  SPAN scenario 16:
                  <br />
                  Underlying equity price down extreme move (cover 35% of loss)
                  <br />
                  Time reduced by 2 days
                </span>
              </Tooltip>
            </th>
          </tr>
        </thead>
        <tbody>
          <OptionsBody groups={groups} hideKeys={hideKeys} isDark={isDark} />
        </tbody>
      </table>
    </div>
  );
}

const OptionsBody = (props) => {
  return (
    <>
      {Object.keys(props.groups).map((group) => (
        <OptionsGroup
          options={props.groups[group].positions}
          hideKeys={props.hideKeys}
          isDark={props.isDark}
          key={props.groups[group].underlyingCode}
        />
      ))}
    </>
  );
};

export default OptionsTable;
