import React, { useContext } from "react";
import ThemeContext from "./Contexts/ThemeContext.js";
import Footer from "./Components/Footer/index.js";
import screenshot from "./Common/screenshot.png";

function Home() {
  const { theme } = useContext(ThemeContext);
  const bgAccent = theme === "dark" ? "bg-dark" : "bg-light";

  return (
    <>
      <div
        className={
          "position-relative overflow-hidden p-3 m-md-3 text-center rounded-3 " +
          bgAccent
        }
      >
        <div className="col-md-5 mx-auto my-5">
          <h1 className="display-4 font-weight-normal">
            ASX Exchange Traded Options Margins Analysis
          </h1>
          <p className="lead font-weight-normal">
            Detailed analysis of Exchange Traded Option porfolio's margins.
          </p>
          <div class="d-inline-flex gap-2">
            <a
              href="/portfolio"
              class="d-inline-flex align-items-center btn btn-primary btn-lg px-4 rounded-pill"
              role="button"
            >
              Launch Portfolio
            </a>
            {/* <button
              
              href="/login"
              class="btn btn-outline-secondary btn-lg px-4 rounded-pill"
              type="button"
              onClick={isAuthenticated() ? logout : login}
            >
              Login
            </button> */}
          </div>
        </div>
      </div>

      <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
        <a href="/portfolio">
          <img
            className="mb-2 img-fluid screen-shot"
            src={screenshot}
            alt=""
            width="745"
          />
        </a>
        <div className="product-device shadow-sm d-none d-md-block"></div>
        <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
      </div>

      <div className="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
        <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div className="my-3 p-3">
            <div className="masthead-followup-icon d-inline-block mb-2 text-white bg-success">
              <svg
                width="32"
                height="32"
                focusable="false"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z"
                />
              </svg>
            </div>
            <h2 className="display-5">Margin Estimator</h2>
            <p className="lead">
              Complete breakdown of each component of the SPAN margin.
              Understand the significance of each position and how it
              contributes to the total margin.
            </p>
          </div>
        </div>
        <div className="mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
          <div className="my-3 py-3">
            <div className="masthead-followup-icon d-inline-block mb-2 text-white color-b">
              <svg
                width="32"
                height="32"
                focusable="false"
                viewBox="0 0 16 16"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
              </svg>
            </div>
            <h2 className="display-5">Volatility Analysis</h2>
            <p className="lead">
              Volatility statistics and key monthly overviews.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
