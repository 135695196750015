import { textColor } from "../../../../Common/colors";

export const TotalCell = (props) => {
  const style = Object.assign({}, props.styling);
  // Don't modify cell colour when value is 0.
  const className = textColor(props.value, props.isDark);
  return (
    <td style={style} className={props.className}>
      <span className={props.className + className}>
        {props.value.toLocaleString()}
      </span>
    </td>
  );
};
