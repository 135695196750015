import React from "react";
import "./footer.css";

const Disclaimer = () => {
  return (
    <div className="footer border-top">
      <div className="container">
        <div className="row">
          <div className="text-center col text-muted">
            <small>
              The data on this page has been provided by external vendors and
              has not been verified by us.
            </small>
            <br />
            <small>
              ETO Margins does not accept responsibility for any losses suffered
              due to reliance on the data, calculations, or values.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
