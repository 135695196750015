import { Badge } from "react-bootstrap";
import { shortDate, getCellColor, number } from "../../../../common";
import { textColor } from "../../../../Common/colors";

export const OptionRow = ({ option, highestKey, hideKeys, isDark }) => (
  <tr className="option-row">
    <td>{option.optionCode}</td>
    <td>
      <span className={textColor(option.units, isDark)}>{option.units}</span>
    </td>
    <td>{option.expiryDate ? shortDate(new Date(option.expiryDate)) : null}</td>
    <td>{option.exercisePrice}</td>
    <td>
      <Badge
        bg={option.optionType === "C" ? "success" : "danger"}
        className="bg-opacity-50"
      >
        {option.optionType === "C" ? "Call" : "Put"}
      </Badge>
    </td>
    <td>{option.contractSize}</td>
    <td className="text-end">{(option.volatility * 100).toLocaleString()}%</td>
    <td className="text-end">{option.contractDelta.toLocaleString()}</td>
    <td className="text-end">{option.netDelta.toLocaleString()}</td>
    <td className="text-end">{option.premium.toLocaleString()}</td>
    <td className={getCellColor("r1", highestKey, hideKeys, 25)}>
      {number(-option.r1)}
    </td>
    <td className={getCellColor("r2", highestKey, hideKeys, 25)}>
      {number(-option.r2)}
    </td>
    <td className={getCellColor("r3", highestKey, hideKeys, 25)}>
      {number(-option.r3)}
    </td>
    <td className={getCellColor("r4", highestKey, hideKeys, 25)}>
      {number(-option.r4)}
    </td>
    <td className={getCellColor("r5", highestKey, hideKeys, 25)}>
      {number(-option.r5)}
    </td>
    <td className={getCellColor("r6", highestKey, hideKeys, 25)}>
      {number(-option.r6)}
    </td>
    <td className={getCellColor("r7", highestKey, hideKeys, 25)}>
      {number(-option.r7)}
    </td>
    <td className={getCellColor("r8", highestKey, hideKeys, 25)}>
      {number(-option.r8)}
    </td>
    <td className={getCellColor("r9", highestKey, hideKeys, 25)}>
      {number(-option.r9)}
    </td>
    <td className={getCellColor("r10", highestKey, hideKeys, 25)}>
      {number(-option.r10)}
    </td>
    <td className={getCellColor("r11", highestKey, hideKeys, 25)}>
      {number(-option.r11)}
    </td>
    <td className={getCellColor("r12", highestKey, hideKeys, 25)}>
      {number(-option.r12)}
    </td>
    <td className={getCellColor("r13", highestKey, hideKeys, 25)}>
      {number(-option.r13)}
    </td>
    <td className={getCellColor("r14", highestKey, hideKeys, 25)}>
      {number(-option.r14)}
    </td>
    <td className={getCellColor("r15", highestKey, hideKeys, 25)}>
      {number(-option.r15)}
    </td>
    <td className={getCellColor("r16", highestKey, hideKeys, 25)}>
      {number(-option.r16)}
    </td>
  </tr>
);
