export async function getMargins(positions, auth = null, portfolioIndex = 0) {
  const response = await fetch(
    `${process.env.REACT_APP_POSITIONS_API_URI}/api/v1/margin`,
    {
      headers: getHeaders(auth),
      method: "POST",
      body: JSON.stringify({
        portfolioIndex: portfolioIndex,
        positions: positions,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Network error.");
  }

  const data = await response.json();

  return data;
}

function getHeaders(auth) {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (auth?.isAuthenticated()) {
    headers.Authorization = `Bearer ${auth.getAccessToken()}`;
  }

  return headers;
}
