import React from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Auth from "./Auth/Auth";
import Header from "./Header";
import Home from "./Home";
import Account from "./Account";
import Callback from "./Callback";
import Portfolio from "./Components/Portfolio";
import Parameters from "./Components/Parameters/MarginParameters";
import InterCommoditySpreads from "./Components/Parameters/InterCommoditySpreads";
import VolMonthlyAverage from "./Components/Parameters/VolMonthlyAverage";
import Help from "./Components/MarkdownPages/Help";
import Faqs from "./Components/MarkdownPages/Faqs";
import Links from "./Components/MarkdownPages/Links";
import Contact from "./Components/MarkdownPages/Contact";
import About from "./Components/MarkdownPages/About";
import Privacy from "./Components/MarkdownPages/Privacy";
import Terms from "./Components/MarkdownPages/Terms";
import { ToastContainer } from "react-toastify";
import ThemeContext from "./Contexts/ThemeContext";
import useTheme from "./Hooks/useTheme";

function App() {
  let navigate = useNavigate();
  const auth = new Auth(navigate);

  const theme = useTheme();

  return (
    <>
      <ThemeContext.Provider value={theme}>
        <Header auth={auth} />
        <Routes>
          <Route
            path="/"
            element={
              <RedirectIfLoggedIn auth={auth}>
                <Home />
              </RedirectIfLoggedIn>
            }
          />
          <Route
            path="/account"
            element={
              <RequireAuth auth={auth}>
                <Account auth={auth} />
              </RequireAuth>
            }
          />
          <Route path="/portfolio" element={<Portfolio auth={auth} />} />
          <Route
            path="/parameters"
            element={
              <RequireAuth auth={auth}>
                <Parameters auth={auth} />
              </RequireAuth>
            }
          />
          <Route
            path="/intercommodityspreads"
            element={
              <RequireAuth auth={auth}>
                <InterCommoditySpreads auth={auth} />
              </RequireAuth>
            }
          />
          <Route
            path="/volatility"
            element={
              <RequireAuth auth={auth}>
                <VolMonthlyAverage auth={auth} />
              </RequireAuth>
            }
          />
          <Route path="/callback" element={<Callback auth={auth} />} />
          <Route path="/help" element={<Help auth={auth} />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/links" element={<Links />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </ThemeContext.Provider>
    </>
  );
}

function RequireAuth({ children, auth }) {
  if (!auth?.isAuthenticated()) {
    auth.login();
    return;
  }

  return children;
}

function RedirectIfLoggedIn({ children, auth }) {
  if (auth.isAuthenticated()) {
    return <Navigate to="/portfolio" replace />;
  }

  return children;
}

export default App;
