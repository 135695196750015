import { currency } from "../../common";
import { textColor } from "../../Common/colors";

export const TotalCell = (props) => {
  const className = textColor(props.value, props.isDark);

  return (
    <span className={props.className + " " + className}>
      {currency(props.value)}
    </span>
  );
};
