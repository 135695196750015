import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function Callback({ auth }) {
  let location = useLocation();

  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      auth.handleAuthentication();
    } else {
      throw new Error("Invalid callback URL.");
    }
  }, [location, auth]);

  return (
    <Spinner
      animation="border"
      role="status"
      variant="secondary"
      className="spinner-center"
    >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export default Callback;
