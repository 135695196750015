import { TotalCell } from "./TotalCell";
import { getCellColor } from "../../../../common";

const TotalRow = (props) => {
  const { options, highestKey, isDark } = props;
  return (
    <tr className="option-row-total text-end">
      <td className="border-bottom-0" />
      <td className="border-bottom-0" />
      <td className="border-bottom-0" />
      <td className="border-bottom-0" />
      <td className="border-bottom-0" />
      <td className="border-bottom-0" />
      <td className="border-bottom-0" />
      <td>
        <strong>Total</strong>
      </td>
      <TotalCell value={options.netDelta} isDark={isDark} />
      <TotalCell value={options.premium} isDark={isDark} />
      <TotalCell
        className={getCellColor("r1", highestKey, props.hideKeys)}
        value={-options.r1}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r2", highestKey, props.hideKeys)}
        value={-options.r2}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r3", highestKey, props.hideKeys)}
        value={-options.r3}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r4", highestKey, props.hideKeys)}
        value={-options.r4}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r5", highestKey, props.hideKeys)}
        value={-options.r5}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r6", highestKey, props.hideKeys)}
        value={-options.r6}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r7", highestKey, props.hideKeys)}
        value={-options.r7}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r8", highestKey, props.hideKeys)}
        value={-options.r8}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r9", highestKey, props.hideKeys)}
        value={-options.r9}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r10", highestKey, props.hideKeys)}
        value={-options.r10}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r11", highestKey, props.hideKeys)}
        value={-options.r11}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r12", highestKey, props.hideKeys)}
        value={-options.r12}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r13", highestKey, props.hideKeys)}
        value={-options.r13}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r14", highestKey, props.hideKeys)}
        value={-options.r14}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r15", highestKey, props.hideKeys)}
        value={-options.r15}
        isDark={isDark}
      />
      <TotalCell
        className={getCellColor("r16", highestKey, props.hideKeys)}
        value={-options.r16}
        isDark={isDark}
      />
    </tr>
  );
};

export default TotalRow;
