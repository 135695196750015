export function textColor(value, isDark) {
  //return !value ? "" : value > 0 ? "green-text" : "red-text";
  //return !value ? "" : value > 0 ? "text-success" : "text-danger";
  if (isDark) {
    return !value
      ? ""
      : value > 0
      ? "text-success-emphasis"
      : "text-danger-emphasis";
  }

  return !value ? "" : value > 0 ? "text-success" : "text-danger";
}
