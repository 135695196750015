import React, { useState, useEffect } from "react";
import { Container, Spinner, Table } from "react-bootstrap";
import ErrorMessage from "../ErrorMessage";
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
} from "@tanstack/react-table";

interface Props {
  auth: any;
}

type OptionClass = {
  id: number;
  rateVal: number;
  legACode: string;
  sideA: string;
  legASpreadMultiplier: number;
  legBCode: string;
  sideB: string;
  legBSpreadMultiplier: number;
};

function InterCommoditySpreads({ auth }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getSpreads() {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_POSITIONS_API_URI}/api/v1/intercommodityspreads`,
          {
            headers: { Authorization: `Bearer ${auth.getAccessToken()}` },
          }
        );
        if (!response.ok) {
          throw new Error("Network error.");
        }
        const data = await response.json();
        var dataWithId = data.map((val: any, ix: number) => {
          val.id = ++ix;
          return val;
        });
        setData(dataWithId);
      } catch (exception) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    getSpreads();
  }, [auth]);

  const columns: ColumnDef<OptionClass>[] = [
    {
      accessorKey: "id",
      header: "Priority",
    },
    {
      accessorKey: "rateVal",
      header: "Rate %",
    },
    {
      accessorKey: "legACode",
      header: "Leg Code A",
    },
    {
      accessorKey: "sideA",
      header: "Side A",
    },
    {
      accessorKey: "legASpreadMultiplier",
      header: "Side A Multiplier",
    },
    {
      accessorKey: "legBCode",
      header: "Leg Code B",
    },
    {
      accessorKey: "sideB",
      header: "Side B",
    },
    {
      accessorKey: "legBSpreadMultiplier",
      header: "Side B Multiplier",
    },
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (loading) {
    return (
      <Spinner
        animation="border"
        role="status"
        variant="secondary"
        className="spinner-center"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Container fluid className="underlying-main pt-2">
      <Table striped hover size="sm" className="table-fixed">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default InterCommoditySpreads;
