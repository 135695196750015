const LOCAL_SETTINGS_KEY = "LOCAL_SETTINGS_KEY";
const LOCAL_POSITIONS_KEY = "LOCAL_POSITIONS_KEY";

export function savePositions(positions) {
  localStorage.setItem(LOCAL_POSITIONS_KEY, JSON.stringify(positions));
}

export function saveSettings(settings) {
  localStorage.setItem(LOCAL_SETTINGS_KEY, JSON.stringify(settings));
}

export function initSettings() {
  let settings = JSON.parse(localStorage.getItem(LOCAL_SETTINGS_KEY));
  if (
    !(
      settings &&
      settings.premiumMultiplier > 0 &&
      settings.premiumMultiplier < 10 &&
      settings.riskMultiplier > 0 &&
      settings.riskMultiplier < 10
    )
  ) {
    settings = {
      premiumMultiplier: 1,
      riskMultiplier: 2.5,
    };
    localStorage.setItem(LOCAL_SETTINGS_KEY, JSON.stringify(settings));
  }

  return settings;
}

export function getPositions() {
  const value = localStorage.getItem(LOCAL_POSITIONS_KEY);
  if (value) {
    try {
      const localPositions = JSON.parse(value);
      return localPositions.map((x) => {
        return { securityCode: x.securityCode, units: x.units };
      });
    } catch (error) {
      return [];
    }
  }
}
