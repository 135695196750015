import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import markdownPath from "../../Markdown/Help.md";
import Footer from "../Footer/index.js";
import gfm from "remark-gfm";

const Help = () => {
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    fetch(markdownPath)
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  });

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          children={String(children).replace(/\n$/, "")}
          language={match[1]}
          PreTag="div"
          {...props}
        />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  };

  return (
    <>
      <div className="container pt-3 pb-4 markdown-body">
        <ReactMarkdown
          components={components}
          remarkPlugins={[gfm]}
          children={markdown}
        ></ReactMarkdown>
      </div>
      <Footer />
    </>
  );
};

export default Help;
