import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import Footer from "../Footer/index.js";
import gfm from 'remark-gfm'

const Markdown = ({markdownPath}) => {
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    fetch(markdownPath)
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  });

  const renderers = {
    code: ({ language, value }) => {
      return (
        <SyntaxHighlighter
          language={language}
          children={value}
        />
      );
    },
  };

  return (
    <>
      <div className="container pt-3 pb-4 markdown-body">
        <ReactMarkdown
          renderers={renderers}
          plugins={[gfm, { tableCellPadding: 5 }]}
          children={markdown}
        ></ReactMarkdown>
      </div>
      <Footer />
    </>
  );
};

export default Markdown;
